<template>
  <div v-if="getAppLoader" class="main main_loader">
    <Loader class="main_loader_ch" />
  </div>
  <el-row v-else>
    <div>
        <h2>Log in as</h2>
        <h4 v-if="user_email">{{ user_email }}</h4>

        <a class="btn" v-bind:href=app_url>Open the desktop app</a>

        <p v-if="user_email">
            Not {{ user_email }}? <a href="/login" @click.prevent="logout">Log in with another account</a>
        </p>
    </div>
  </el-row>
</template>

<script>

    import { mapMutations, mapActions, mapGetters } from "vuex"
    import router from "@/router"

    export default {
        props: {
            span: [Number]
        },
        data() {
            return {
                user_email: this.$route.query.useremail,
                app_url: 'taskpal://clone_token='+ localStorage.getItem('w_clone_token') +'&token='+ localStorage.getItem('w_token')
            }
        },
        sockets: {
            connect: async function () {

                console.log('Socket Connect')

                this.alerts = [{
                    icon: true,
                    text: "Syncing",
                    class: "alert-message"
                }]

                await this.handleGetData()

                if (this.interval) {
                    clearInterval(this.interval)
                }

            },
            disconnect: async function (e) {

                console.log('Socket Disconnect')

                this.handleSocketDisconnected()

                setTimeout(() => {
                    this.$socket.connect()
                }, 10000)

                this.interval = setInterval(() => {
                    console.log('setInterval:30')
                    this.$socket.connect()
                }, 30000)

            },
            connect_error: async function (e) {
                console.log('Socket Connect Error')
            }
        },
        methods: {
            ...mapActions([
                "fetchUserData",
                "socket_send_firebase_token",
                "SOCKET_IN_Company",
                "getData",
                "addChatUnreadMessagesRecievedWhileUpdating"
            ]),
            ...mapMutations([
                "setMessage", 
                "onAppLoader", 
                "offAppLoader",
                "setProjects",
                "clearComments",
                "clearTasks",
                "clearUserUsedFileSize",
                "clearUserAllFileSize",
            ]),
            logout() 
            {
                this.setProjects([]);
                this.clearComments();
                this.clearUserUsedFileSize();
                this.clearUserAllFileSize();
                this.clearTasks();
                this.$socket.disconnect();
                localStorage.removeItem("w_token");
                localStorage.removeItem("w_clone_token");
                this.$router.push({
                    path: "/login",
                    query: {
                        app: 1
                    }
                });
                console.log("User Logout");
            },
            resetSubtaskOpen() {
                const len = Object.keys(this.getTasks).length
                if (len > 0) {
                    for (let list in this.getTasks) {
                        const currentProject = this.getTasks[list]
                        for (let i = 0; i < currentProject.length; i++) {
                            if (currentProject[i].parent && currentProject[i].unread_message) {
                                localStorage.setItem(currentProject[i].parent, true)
                                EventEmitter.$emit('openSubtask', currentProject[i]._id)
                                i = currentProject.length + 5
                            }
                        }
                    }
                }
            },
            async handleGetData() {
                return await this.getData()
                .then((res) => {

                    this.resetSubtaskOpen()
                    this.alerts = this.alerts.filter(
                        (alert) => alert.text !== "Syncing"
                    )

                    this.alerts.push({
                        icon: true,
                        text: "Synced",
                        class: "alert-message"
                    })

                    setTimeout(() => {
                        this.addChatUnreadMessagesRecievedWhileUpdating()
                    }, 0)

                    setTimeout(() => {
                        this.alerts = this.alerts.filter(
                            (alert) => alert.text !== "Synced"
                        )
                    }, 1000)
                })
                .catch((err) => {
                    console.log('Get Data Error', err)
                    this.$socket.disconnect()
                })
            },
            handleSocketDisconnected() {
                this.alerts = [];
                this.alerts.push({
                    icon: true,
                    text: "Connecting",
                    class: "alert-message"
                })
            },
        },
        computed: {
            ...mapGetters(["getAppLoader", "getUserData", "getTasks"]),
            dynamicUserEmail() {
                return this.getUserData.email
            }
        },
        mounted() {
            this.$socket.io.opts.transportOptions.polling.extraHeaders.Authorization = localStorage.getItem("w_token")
            this.$socket.connect()
            if (! this.user_email)
            {
                let findEmail = setInterval(() => {
                    if (this.dynamicUserEmail)
                    {
                        clearInterval(findEmail)
                        this.user_email = this.dynamicUserEmail
                        localStorage.removeItem('app_web_auth')
                    }
                }, 500)
            }
        }
    }


</script>

<style lang="css">
.auth_loader {
  display: flex !important;
  left: 0 !important;
  transform: translateX(0) !important;
  margin: 15% auto !important;
}
#app {
    background-image: none !important;
} 
.logo {
    
    background-size: contain;
    display: inline-block;
    height: 40px;
    width: 40px;
}
dappauth-layout {
    background: white;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 4em;
}
dappauth-layout > div {
    padding-bottom: 3em;
}
dappauth-layout div p {
    width: 100%;
    max-width: 330px;
    margin: 0 auto;
}
dappauth-layout a {
    color: #e58a8a;
    transition: all ease-in-out .3s;
}
dappauth-layout a:hover {
    text-decoration: underline;
}
dappauth-layout a.btn {
    text-decoration: none;
    font-size: 16px;
    transition: all ease-in-out .3s;
    background-color: #e58a8a;
    color: black;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 1em;
    height: auto;
    display: inline-block;
    margin: 2em 0;
    border-radius: 8px;
    font-weight: bold;
    width: calc(100% - 2em);
    border: 0
}
dappauth-layout a.btn:hover {
    color: white;
}
dappauth-layout h2 {
    margin-bottom: 0.25em;
    font-size: 2em;
}
dappauth-layout h4 {
    font-size: 1.5em;
}
</style>